@import url('./date-picker-css/calendar.css');
@import url('./date-picker-css/date-picker.css');
@import url('./date-picker-css/date-range-picker.css');

.dropdown-item.active,
.dropdown-item:active {
	@apply !bg-gray-100;
}

.custom-switch .react-switch-handle {
	@apply shadow-none !important;
}

.action-btn {
	@apply flex items-center space-x-2 rounded border px-2.5 py-[3px] !font-ibmplex text-sm text-gray-800;
}

.btn-gray {
	@apply border-gray2 bg-gray2 !text-white hover:text-white;
}
.action-btn{
    @apply px-2.5 py-[2px] text-[13px] !font-ibmplex border rounded text-gray-800 flex items-center space-x-2 
}

.btn-gray-outline {
	@apply border-gray2 bg-white text-gray2 hover:bg-gray-100;
}

.btn-red-outline {
	@apply border-red-600 bg-white text-red-600 hover:bg-red-50;
}

.btn-info-outline {
	@apply border-[#50a5f1] bg-white text-[#50a5f1] hover:bg-[#50a5f110];
}

.btn-primary-outline {
	@apply border-[#5b73e8] bg-white text-[#5b73e8] hover:bg-[#5b73e810];
}

.form-check-input-danger {
	border: 1px solid #f46a6a !important;
}

.form-check-input-danger:checked {
	background-color: #f46a6a !important;
}

.custom-select {
	position: relative;
}

.custom-select select {
	appearance: none;
	-webkit-appearance: none;
	width: 100%;
	padding: 0.675em 6em 0.675em 1em;
	background-color: #fff;
	border: 1px solid #caced1;
	border-radius: 0.25rem;
	color: #000;
	cursor: pointer;
}

.custom-select::before,
.custom-select::after {
	--size: 0.3rem;
	position: absolute;
	content: '';
	right: 8px;
	pointer-events: none;
}

.custom-select::before {
	border-left: var(--size) solid transparent;
	border-right: var(--size) solid transparent;
	border-bottom: var(--size) solid black;
	top: 35%;
}

.custom-select::after {
	border-left: var(--size) solid transparent;
	border-right: var(--size) solid transparent;
	border-top: var(--size) solid black;
	top: 60%;
}

.form-container {
	@apply flex  flex-col divide-y lg:min-w-[775px] lg:max-w-[775px];
}
.form-row {
	@apply flex flex-col items-start justify-between gap-[10px] !py-5 lg:flex-row lg:gap-[20px];
}

.form-heading h3 {
	@apply text-lg font-bold;
}

.form-heading p {
	@apply text-xs text-[#74788D];
}

.form-fields-card {
	@apply min-w-[440px] max-w-[440px] rounded bg-white  !p-5;
}
.my-form-fields-card {
	@apply min-w-[440px] max-w-[440px] rounded bg-white  !p-5;
}
.form-field-workflow{
	@apply rounded bg-white !p-6 w-full;
}
.is-invalid {
	@apply border-[#f46a6a]
}

.form-check-input:disabled,.form-control:disabled{
	@apply !cursor-not-allowed
}

.exp-dropdown-item{
	@apply hover:bg-gray-100  hover:text-gray-800 !cursor-pointer !rounded-none font-normal not-italic leading-4 text-[#495057] transition-all !border-l-2 border-transparent hover:!border-main !px-3 !py-2
}

.exp-dropdown-item-active{
	@apply !border-l-2 border-main bg-gray-100 leading-4 !px-3 !py-2 !cursor-pointer
}


.btn-filter{
	@apply bg-transparent hover:bg-slate-100 rounded !border px-3 py-2	
}

.invalid-tooltip-container .danger {
  color: #ffffff;
  background-color: rgb(233, 0, 0);
}